<template>
  <div class="bkColor">
    <headTitleVue :title="title + '文件导入'" :routerPath="routerPath"></headTitleVue>
    <div class="bodyContent">
      <div class="tips">
        <span style="font-weight: 600">上传须知：</span>
        <span v-show="flag != 3">请根据导出的表格文件，修改
        </span>
        <span>
          <span v-if="flag == 1">L列<span style="color: #1989fa">“付款类型名”</span>后进行文件导入，系统只会更新L列<span
              style="color: #1989fa">“付款类型名”</span>信息，其他列信息不会更新。</span>
          <span v-else-if="flag == 2"> I列<span style="color: #1989fa">“费用类别名”</span>和K列<span
              style="color: #1989fa">“费用详细类别名”</span>后进行文件导入，系统只会更新I列<span style="color: #1989fa">“费用类别名”</span>和K列<span
              style="color: #1989fa">“费用详细类别名”</span></span>
          <span v-else-if="flag == 3">往来金额可根据导出的表格内容进行修改并导入</span>
        </span>
      </div>
      <div class="tips" v-show="flag == 1">
        <span style="font-weight: 600">费用类型请根据项目或非项目，从以下可选项中选择</span>
        <div>
          项目费用类别：货物采购、外包、技术服务、劳务服务、招待费、其他
        </div>
        <p></p>
        <div>非项目费用类别：市场、行政、交通、税费、工资</div>
      </div>
      <div class="tips" v-show="flag == 2">
        <span style="font-weight: 600">请根据项目或非项目，从以下可选项中选择</span>
        <div>
          费用类型：项目、市场、行政、交通、税费、工资
        </div>
        <p></p>
        <div>
          详细类型：办公费用、财务费用、交通费用、汽车费用、税费、职工薪酬、餐费、差旅费、其他
        </div>
      </div>
      <van-uploader v-model="uploader" @click-preview="viewFile" max-count="1" accept="file" upload-icon="plus"
        :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile" :preview-full-image="false" />
    </div>
  </div>
</template>

<script>
import headTitleVue from "../components/headTitle.vue";
import Vue from "vue";
import { Uploader, Toast } from "vant";
Vue.use(Uploader);
Vue.use(Toast);
import { paymentImportData } from "../api/payment";
import { reimbursementImportData } from "../api/reimbursement";
import { transactionImportData } from "../api/transactionAmount";

export default {
  name: "fileImport",
  components: { headTitleVue },
  data() {
    return {
      title: "",
      routerPath: "",
      uploader: [], //附件
      uploading: false, //是否正在上传
      flag: 0, //标记 1付款 2报销 3往来
    };
  },
  mounted() {
    this.title = this.$route.query.title;
    switch (this.title) {
      case '付款':
        this.flag = 1
        break
      case '报销':
        this.flag = 2
        break
      case '往来金额':
        this.flag = 3
        break
    }
    this.routerPath = this.$route.query.routerPath;
    console.log(this.flag)
  },
  methods: {
    beforeRead(file) {
      //上传之前校验
      console.log(file, reimbursementImportData);
      if (file.name.indexOf(".xlsx") == -1 || file.name.indexOf(".xls") == -1) {
        Toast.fail("请按导出的文件格式导入xls或xlsx文件");
        return false;
      }
      if (file.size > 30 * 1024 * 1024) {
        Toast.fail("只允许上传30M的文件");
        return false;
      }
      return true;
    },
    async afterRead(file) {
      //文件读取完成后的回调函数
      file.status = "uploading";
      file.message = "上传中...";
      this.uploading = true;
      let fd = new FormData();
      fd.append("files", file.file);
      if (this.title == "报销") {
        reimbursementImportData(fd)
          .then((res) => {
            if (res.data.code == 200 && res.data.data) {
              Toast.success(res.data.data);
              this.uploading = false;
            } else if (res.data.code == 200 && !res.data.data) {
              Toast.success("上传成功");
            }
            file.status = "";
            file.message = "";
          })
          .catch(() => {
            this.uploading = false;
            this.uploader = [];
          });
      } else if (this.title == "付款") {
        paymentImportData(fd)
          .then((res) => {
            if (res.data.code == 200 && res.data.data) {
              Toast.success(res.data.data);
            } else if (res.data.code == 200 && !res.data.data) {
              Toast.success("上传成功");
            }
            file.status = "";
            file.message = "";
          })
          .catch(() => {
            this.uploading = false;
            this.uploader = [];
          });
      } else if (this.title == "往来金额") {
        transactionImportData(fd)
          .then((res) => {
            if (res.data.code == 200 && res.data.data) {
              Toast.success(res.data.data);
            } else if (res.data.code == 200 && !res.data.data) {
              Toast.success("上传成功");
            }
            file.status = "";
            file.message = "";
          })
          .catch(() => {
            this.uploading = false;
            this.uploader = [];
          });
      }
    },
    deleteFile() {
      this.uploader = []
    },
    viewFile(file) {
      if (
        file.url.indexOf(".jpg") !== -1 ||
        file.url.indexOf(".jpeg") !== -1 ||
        file.url.indexOf(".png") !== -1
      ) {
        return;
      }
      this.$router.push({
        path: "/preview",
        query: { url: file.url, routerPath: "/fileImport" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;
}

.tips {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

.bodyContent {
  background: #fff;
  padding-bottom: 20vh;
}
</style>