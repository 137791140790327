import request from "./request";

//新增往来金额
export function transactionAdd(data) {
    return request({
        url: '/project/transaction',
        method: 'post',
        data
    })
}

//修改往来金额
export function transactionPut(data) {
    return request({
        url: '/project/transaction',
        method: 'put',
        data
    })
}

//获取往来金额
export function getTransaction(id) {
    return request({
        url: '/project/transaction/' + id,
        method: 'get'
    })
}

//删除往来金额
export function transactionDeltel(ids) {
    return request({
        url: '/project/transaction/' + ids,
        method: 'delete'
    })
}

//导出往来金额
export function transactionExport(data) {
    return request({
        url: '/project/transaction/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//导入往来金额
export function transactionImportData(data) {
    return request({
        url: '/project/transaction/importData',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

//获取往来金额列表
export function transactionList(query) {
    return request({
        url: '/project/transaction/list',
        params: query
    })
}

//财务最后确认步骤
export function transactionUpdateState(ids, isSettle) {
    return request({
        url: `/project/transaction/updateState/${ids}/${isSettle}`,
        method: 'put'
    })
}